import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as storyActions from "store/story/actions";
import _debounce from 'lodash/debounce';
import { ReactReader, ReactReaderStyle, Reader } from 'react-reader';
import { Modal, Select } from 'antd';
import MeaningModal from './MeaningModal';
import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";
// import { FaPlay, FaPause } from "react-icons/fa";
import { IoIosPause, IoIosPlay } from "react-icons/io";
import * as EVENT from '../../utils/eventKeys'
import { formatDate } from "../../utils/commonUtils";
import { version } from '../../../package.json'
import { Analytics } from "../../services/analytics";
import { v4 as uuidv4 } from "uuid";
import { storage as LocalStorage } from "services/config/storage";
// import { GrResume } from "react-icons/gr";


const EpubReader = (props) => {
    const { Option } = Select;
    const [location, setLocation] = useState(null);
    const [isReading, setIsReading] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [currentWordIndex, setCurrentWordIndex] = useState(1);
    const [currentParaIndex, setCurrentParaIndex] = useState(1);
    const [fontSize, setFontSize] = useState(100);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [locationChanged, setLocationChanged] = useState(false)
    const [lastPage, setLastPage] = useState(false)
    const [openShowMeaningModal, setOpenShowMeaningModal] = useState(false)
    const [meaningData, setMeaningData] = useState(null)
    const [voices, setVoices] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false)
    const [selectedVoice, setSelectedVoice] = useState('');
    const [selections, setSelections] = useState([])

    const renditionRef = useRef(null);
    const viewerRef = useRef(null);
    const utteranceRef = useRef(null);
    const wordsRef = useRef({});
    const textNodesRef = useRef([]);
    const view_id = uuidv4()
    // console.log(props.speechSynthesisRef,selectedVoice)

    useEffect(() => {
        if (!props.speechSynthesisRef.speaking) {
            setIsReading(false)
        }
    }, [props.speechSynthesisRef.speaking])

    const onLocationChanged = (epubcfi) => {
        setLocation(epubcfi);
        setLocationChanged(true)
    };

    const loadVoices = () => {
        const voices = props.speechSynthesisRef.getVoices();
        let defaultVoices = voices.filter((voice) => voice.localService === true)
        // console.log(defaultVoices)
        setVoices(defaultVoices);
        if (voices.length > 0) {
            setSelectedVoice(voices[1].name);
        }
    };

    const setTextNodes = () => {
        if (viewerRef.current) {
            const doc_body = viewerRef.current.querySelector('iframe')?.contentWindow?.document?.body

            let textNodes = getTextNodes(doc_body);
            textNodesRef.current = textNodes

        }
    }

    useEffect(() => {
        loadVoices();
        if (props.speechSynthesisRef.onvoiceschanged !== undefined) {
            props.speechSynthesisRef.onvoiceschanged = loadVoices;
        }

        if (viewerRef.current && isLoaded) {
            setTextNodes()
        }


    }, [isLoaded])

    useEffect(() => {
        if (renditionRef.current) {
            renditionRef.current.themes.fontSize(`${fontSize}%`);
        }
    }, [fontSize]);

    useEffect(() => {
        // console.log(`#word-${currentParaIndex}${currentWordIndex}`)
        // if(currentWordIndex ===0) return
        if (viewerRef.current) {
            // const doc = viewerRef.current.querySelector('iframe')?.contentWindow.document
            const doc_body = viewerRef.current.querySelector('iframe')?.contentWindow?.document?.body
            const words = doc_body?.getElementsByTagName('span');
            let currentWord = ''
            // console.log(words)
            for (let i = 0; i < words?.length; i++) {
                // console.log(words[i])
                words[i].classList.remove('highlight');
                words[i].style.background = "none"
            }
            if (currentWordIndex === 0 || currentParaIndex === 0) currentWord = doc_body?.querySelector(`#word-${currentParaIndex}${currentWordIndex + 1}`)
            else currentWord = doc_body?.querySelector(`#word-${currentParaIndex}${currentWordIndex + 1}`)
            // console.log(currentWord?.innerText, `#word-${currentParaIndex}${currentWordIndex}`)
            if (currentWord) {
                currentWord.classList.add('highlight');
                currentWord.style.background = "yellow"
            }
        }
    }, [currentWordIndex]);

    useEffect(() => {
        if (renditionRef.current) {
            const debouncedSetRenderSelection = _debounce(async (cfiRange, contents) => {
                // console.log(cfiRange)
                if (renditionRef.current) {
                    const cleanedText = cleanText(renditionRef.current.getRange(cfiRange).toString())
                    setSelections(
                        {
                            text: cleanedText,
                            cfiRange
                        }
                    );
                    renditionRef.current.annotations.add(
                        'highlight',
                        cfiRange,
                        {},
                        (e) => console.log('click on selection', cfiRange, e),
                        'hl',
                        { fill: '#c899eb', 'fill-opacity': '0.5', 'mix-blend-mode': 'multiply' }
                    )
                    const selection = contents.window.getSelection();
                    // console.log(selection);
                    selection.removeAllRanges();

                }
            }, 500);
            renditionRef.current.on('selected', debouncedSetRenderSelection)
            return () => {
                renditionRef.current.off('selected', debouncedSetRenderSelection)
            }
        }
    }, [setSelections, selections])

    useEffect(() => {
        getMeaning(selections)
    }, [selections])


    const handleNext = async () => {
        await renditionRef.current.next();
        if (locationChanged) {
            setCurrentPageIndex(currentPageIndex + 1)
            setLocationChanged(false)
            if (renditionRef.current.currentLocation()?.atEnd) {
                setLastPage(true)
            }
        }
        props.speechSynthesisRef.cancel();
        setIsReading(false)
        setIsPaused(false)
        setFontSize(100)
        setTextNodes()



        // bookRef.current.pageFlip().flipNext();
    };

    const handlePrev = async () => {
        await renditionRef.current.prev();
        setTextNodes()
        setLastPage(false)
        setCurrentPageIndex(currentPageIndex - 1)
        props.speechSynthesisRef.cancel();
        setIsReading(false)
        setIsPaused(false)
        setFontSize(100)
        // bookRef.current.pageFlip().flipPrev();
    };

    const handleFontIn = () => {
        if (renditionRef.current) {
            setFontSize((prevSize) => {
                const newSize = prevSize + 10;
                renditionRef.current.themes.fontSize(`${newSize}%`);
                return newSize;
            });
        }
    };

    const handleFontOut = () => {
        if (renditionRef.current) {
            setFontSize((prevSize) => {
                const newSize = prevSize - 10;
                renditionRef.current.themes.fontSize(`${newSize}%`);
                return newSize;
            });
        }
    };

    const ownStyles = {
        ...ReactReaderStyle,
        arrow: {
            ...ReactReaderStyle.arrow,
            display: 'none'
        },
        typography: {
            ...ReactReaderStyle.typography,
            fontSize: '500%'
        }
    }

    const startReading = () => {
        setIsReading(true)
        const doc = viewerRef.current.querySelector('iframe').contentWindow.document
        const doc_body = viewerRef.current.querySelector('iframe').contentWindow.document.body
        let image = doc.querySelectorAll('img')

        let textHtml = `<div>`
        if (textNodesRef.current) {
            textNodesRef.current.forEach((node, ind) => {
                const content = node.textContent
                if (content) {
                    const words = content.split(/\s+/);
                    const wrappedText = words.map((word, index) => `<span id="word-${ind + 1}${index + 1}">${word} </span>`).join('');

                    textHtml = textHtml.concat(wrappedText)
                    textHtml = textHtml.concat('<br/><br/>')
                    utteranceRef.current = new SpeechSynthesisUtterance(content);
                    const voice = voices.find(voice => voice.name === selectedVoice);
                    // console.log(voice)
                    if (voice) {
                        utteranceRef.current.voice = voice;
                    }

                    utteranceRef.current.onboundary = (event) => {
                        console.log(event)
                        if (event.name === 'word') {
                            const charIndex = content.slice(0, event.charIndex).split(/\s+/).length - 1;
                            // console.log(charIndex,currentParaIndex,ind)
                            setCurrentWordIndex(charIndex);
                        }
                        setCurrentParaIndex(ind + 1)
                    };
                    utteranceRef.current.onend = () => {
                        // console.log('ending...',currentParaIndex)
                        setCurrentParaIndex((prev) => prev + 1)
                        setCurrentWordIndex(0);
                        // setIsReading(false)
                    };
                    props.speechSynthesisRef.speak(utteranceRef.current);
                    setIsReading(true);
                }
            })
            textHtml = textHtml.concat('</div>')
            doc_body.innerHTML = textHtml;
        }
        // if (image) {
        //     image[0].style.width = '200px'
        //     image[0].style.height = '200px'
        // }
        doc_body.appendChild(image[0])
        doc_body.style.display = "flex"
        doc_body.style.flexDirection = "column-reverse"
        doc_body.style.gap = '8px'

        logEvents(EVENT.READ_TO_ME_PLAY_BUTTON, {
            [EVENT.READ_TO_ME_STORY_NAME]:props.name ,
            [EVENT.READ_TO_ME_STORY_URL]:props.url ,
        });

    };

    const stopReading = () => {
        if (props.speechSynthesisRef.speaking) {
            props.speechSynthesisRef.cancel();
            setIsReading(false);
            // setCurrentWordIndex(0);
            logEvents(EVENT.READ_TO_ME_STOP_BUTTON, {
                [EVENT.READ_TO_ME_STORY_NAME]: props.name,
                [EVENT.READ_TO_ME_STORY_URL]: props.url,
            });

        }
    };

    const getTextNodes = (element) => {
        console.log(element)
        let textNodes = [];
        let words = {}
        function recursiveSearch(node) {
            node.childNodes.forEach((childNode) => {
                if (childNode.nodeType === Node.TEXT_NODE && childNode.nodeValue.trim().length > 0) {
                    textNodes.push(childNode);
                } else {
                    recursiveSearch(childNode);
                }
            });
        }
        recursiveSearch(element);

        textNodes.forEach((node, index) => {
            words[index] = node.textContent.split(/\s+/)
        })
        wordsRef.current = words
        return textNodes;
    };


    const getMeaning = async (selections) => {
        // console.log(selections)
        if (selections?.text?.length > 0) {
            const res = await props.getMeaningsfromDict(selections?.text)
            if (res) {
                setOpenShowMeaningModal(true)
                setMeaningData(res?.data)
            }
            renditionRef.current.annotations.remove(
                selections?.cfiRange,
                'highlight',
            )
        }
    }

    const cleanText = (text) => {
        return text.replace(/[^a-zA-Z0-9]/g, ''); // Removing all non-alphanumeric characters
    };

    const closeModal = () => {
        setOpenShowMeaningModal(false)
        setMeaningData(null)
    }

    const pauseReading = () => {
        props.speechSynthesisRef.pause()
        setIsPaused(true)
    }

    const resumeReading = () => {
        props.speechSynthesisRef.resume()
        setIsPaused(false)
    }

    const logEvents = (event, extraPayload) => {
        let payload = {};

        payload[EVENT.CHILD_ID] = LocalStorage.fetch.defaultChild()?.id;
        payload[EVENT.SESSION_ID] = LocalStorage.fetch.sessionData()?.session_data;
        payload[EVENT.SOURCE_PAGE_ID] = EVENT.READ_TO_ME_PAGE;
        payload[EVENT.TIMESTAMP] = formatDate(new Date());
        payload[EVENT.VIEW_ID] = view_id;
        payload[EVENT.CHILD_LEVEL] = LocalStorage.fetch.defaultChild()?.grade_level;
        payload[EVENT.SCHOOL_CODE] = LocalStorage.fetch.defaultChild()?.school_code;
        payload[EVENT.SOURCE] = "web";
        payload[EVENT.PARENT_ID] = LocalStorage.fetch.parentData()?.id;
        payload[EVENT.APP_VERSION] = version;
        Analytics.logEvents(event, { ...payload, ...extraPayload });
    };

    return (
        <>
            {
                window.innerWidth > 650 ?
                    <div className='epub_container'>
                        <button className='pdf_control_btn' onClick={handlePrev} disabled={currentPageIndex === 0}>{'<'}</button>
                        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', width: '90%' }} id="epub-render">
                            <div style={{ flex: 1 }} ref={viewerRef}>
                                <ReactReader
                                    url={props.url}
                                    location={location}
                                    locationChanged={onLocationChanged}
                                    showToc={false}
                                    onReady={() => setIsLoaded(true)}
                                    getRendition={(rendition) => {
                                        renditionRef.current = rendition;
                                        renditionRef.current.themes.default({
                                            body: { 'overflow-y': 'scroll', 'height': '100%' },
                                            '::selection': {
                                                background: '#c899eb'
                                            }
                                        });
                                        renditionRef.current.flow('scrolled-doc');
                                        renditionRef.current.themes.fontSize(`${fontSize}%`);
                                        setSelections([])
                                        rendition.on("rendered", () => setIsLoaded(true));
                                        // setIsLoaded(true)

                                    }}
                                    styles={ownStyles}
                                />
                            </div>

                            <div className="epub-controls" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px', gap: '40px' }}>

                                <button onClick={handleFontIn} disabled={fontSize === 200}>+</button>
                                {
                                    currentPageIndex !== 0 &&
                                    <div className="pdf-page-count">
                                        Page {currentPageIndex}
                                    </div>
                                }
                                <button onClick={handleFontOut} disabled={fontSize == 100}>-</button>
                                <button onClick={startReading} disabled={isReading}><GiSpeaker /></button>
                                <button onClick={stopReading} disabled={!isReading}><GiSpeakerOff /></button>
                                {
                                    isPaused ?
                                        <button onClick={resumeReading} disabled={!isReading} ><IoIosPlay /></button>
                                        :
                                        <button onClick={pauseReading} disabled={!isReading}><IoIosPause /></button>
                                }
                                <Select
                                    className='select_voice'
                                    mode={"single"}
                                    autoFocus={true}
                                    style={{ width: 200, height: 40 }}
                                    placeholder={
                                        <span style={{ color: "grey" }}>
                                            Select Voice
                                        </span>
                                    }

                                    value={selectedVoice}
                                    dropdownClassName="custom-dropdown"
                                    onChange={(value) => {

                                        if (props.speechSynthesisRef.speaking) {
                                            props.speechSynthesisRef.cancel();
                                            setIsReading(false);
                                            // setCurrentWordIndex(0);
                                        } setSelectedVoice(value)
                                    }}
                                >
                                    {
                                        voices?.map((m, index) => (
                                            <Option className='select_voice_option' key={m.lang} value={m.name}>
                                                {m.name}
                                            </Option>
                                        ))}
                                </Select>

                            </div>
                        </div>
                        <button className='pdf_control_btn' onClick={handleNext} disabled={lastPage}>{'>'}</button>
                    </div>
                    :
                    <div className='epub_container'>
                        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', width: '90%' }} id="epub-render">
                            <div style={{ flex: 1 }} ref={viewerRef}>
                                <ReactReader
                                    url={props.url}
                                    location={location}
                                    locationChanged={onLocationChanged}
                                    showToc={false}
                                    onReady={() => setIsLoaded(true)}
                                    getRendition={(rendition) => {
                                        renditionRef.current = rendition;
                                        renditionRef.current.themes.default({
                                            body: { 'overflow-y': 'scroll', 'height': '100%' },
                                            '::selection': {
                                                background: '#c899eb'
                                            }
                                        });
                                        renditionRef.current.flow('scrolled-doc');
                                        renditionRef.current.themes.fontSize(`${fontSize}%`);
                                        setSelections([])
                                        rendition.on("rendered", () => setIsLoaded(true));
                                        // setIsLoaded(true)

                                    }}
                                    styles={ownStyles}
                                />
                            </div>

                            <div className="epub-controls" >

                                <button className='pdf_control_btn' onClick={handlePrev} disabled={currentPageIndex === 0}>{'<'}</button>
                                <button onClick={handleFontIn} disabled={fontSize === 200}>+</button>
                                <button onClick={startReading} disabled={isReading}><GiSpeaker /></button>
                                <button onClick={pauseReading} disabled={!isReading}><IoIosPause /></button>
                                <div className="pdf-page-count">
                                    Page {currentPageIndex}
                                </div>
                                <button onClick={resumeReading} disabled={!isReading} ><IoIosPlay /></button>
                                <button onClick={stopReading} disabled={!isReading}><GiSpeakerOff /></button>
                                <button onClick={handleFontOut} disabled={fontSize == 100}>-</button>
                                <button className='pdf_control_btn' onClick={handleNext} disabled={lastPage}>{'>'}</button>

                            </div>
                        </div>
                    </div>
            }

            {
                <Modal
                    centered
                    width="70%"
                    height="70%"
                    className=""
                    visible={openShowMeaningModal}
                    onCancel={() => setOpenShowMeaningModal(false)}
                    footer={null}
                    key={1}
                    destroyOnClose
                >
                    <MeaningModal data={meaningData && meaningData} closeModal={closeModal} />

                </Modal>
            }

        </>
    );
};

export default connect(
    ({ detailedStory }) => ({ ...detailedStory }),
    (dispatch) => bindActionCreators({ ...storyActions }, dispatch)
)(EpubReader);

