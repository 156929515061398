import React, { Component } from 'react';
import { Modal } from 'antd';
import "./index.scss";
import wowImage from 'assets/image/wow.png';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as studentActions from 'store/student/actions';
import DefaultUserIcon from "../../../../assets/image/default-user.png";
import { CrossIcon } from './../../../../assets/image/svg';
import * as teacherActions from "store/teacher/actions";
import * as userActions from "store/user/actions";
import { storage as LocalStorage } from "services/config/storage";
import { type } from 'jquery';
import default_child from "./../../../../assets/image/child_img.png"



class ChildrenModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            childrenData: [],
            teacherSchoolClass: null,
            schoolClassData: [],
            selectedSchoolClassChildren: null,
            allClassMatesData: null,
            childDataFetched: false,
            allDataFetched: false,

        };
    }


    componentDidUpdate(prevProps) {
        const defaultChild = LocalStorage.fetch.defaultChild();
        if( prevProps.updatedChild !== this.props.updatedChild) {
            this.setState({teacherSchoolClass: defaultChild.TeacherSchoolClass});
            this.fetachAllClassMates();
        }
    }

    fetachAllClassMates = async () => {
        try {

            const result = await this.props.getAllClassMates();
            this.setState({ schoolClassData: result, childDataFetched: true });
            await this.findSelectedSchoolClassChildren();
        } catch (err) {
            console.error('Error fetching children data: ', err);
        }
    }

    findSelectedSchoolClassChildren = async () => {
        const schoolClassData = this.state.schoolClassData;
        const teacherlClass = this.state.teacherSchoolClass;

        const selectedSchoolClassChildren = this.getSelectedSchoolClassChildren(schoolClassData,teacherlClass);
    //     this.setState({ selectedSchoolClassChildren: selectedSchoolClassChildren.classmates });
    //     if( selectedSchoolClassChildren.classmates.length > 0) {
    //         await this.fetAllChildDetails();
    //    }
        let classmates = selectedSchoolClassChildren.classmates;
        this.setState({ selectedSchoolClassChildren: classmates });
        let childResponses = new Object();
        if( classmates.length > 0) {
           // for every classmate in classmates
           for(let idx = 0; idx < classmates.length; idx++) {
               let childId = classmates[idx].child_id;
               childResponses[childId] =classmates[idx];
           }
       }
       this.setState({ allClassMatesData: childResponses, allDataFetched: true});
    }

    getSelectedSchoolClassChildren = (schoolClassData, teacherlClass) => {

        for(let idx = 0; idx < schoolClassData.length; idx++) {
            if(schoolClassData[idx].id === teacherlClass) {
                return schoolClassData[idx];
            }
        }


    }


    // fetAllChildDetails = async () => {
    //     let childResponses = new Object();
    //     const { selectedSchoolClassChildren } = this.state;
    //     selectedSchoolClassChildren && selectedSchoolClassChildren.length > 0 && selectedSchoolClassChildren.forEach(async (childId) => {
    //         try {
    //             const resp = await this.props.getChildDetails(childId);
    //             childResponses[childId] = resp;
    //             // this.setState({ allDataFetched: true})
    //         } catch (error) {
    //             console.error(`Error fetching child details for child id ${childId}:`, error);
    //         }
    //     });
    //     this.setState({allClassMatesData: childResponses, allDataFetched: true});

    // }
        
    

    async fetchChildrenData() {
        try {
            let res = await this.props.getBatchChildrenRequest(this.props.batchId);
            res = res.filter(item => item.id !== this.props?.student?.id);
            this.setState({ childrenData: res });
        } catch (e) {
            console.error('Error fetching children data: ', e);
        }
    }

    render() {
        const { allClassMatesData, allDataFetched } = this.state;
        let childrenCards;
        if( !allClassMatesData || Object.keys(allClassMatesData).length <= 0) {
            childrenCards = <div className={'no-data-label'}>No students are registered. Please ask them to register/login with the school code.</div>
        } else {
            childrenCards = allDataFetched &&  allClassMatesData ?
                Object.entries(allClassMatesData)
                    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)) // Sort by keys
                    .map(([key, value]) => (
                        <div className="children-card" key={key}>
                            <div className={'batchmate-content'}>
                                <div className={'profile'}>
                                    <img
                                        className="listing-row-profile-pic"
                                        style={{ backgroundColor: 'white' }}
                                        src={value.image ? value.image : default_child}
                                        alt="Profile"
                                    />
                                    {/* <div className="wow-pic">
                                        <img alt="" src={wowImage} style={value.freadom_child?.is_wow_student ? null : { visibility: "hidden" }} />
                                    </div> */}
                                </div>
                                <div className="batchmate-detail">
                                    <div className="name">
                                        <h2>{value.child__user__name}</h2>
                                    </div>
                                    <div className="stats">
                                        <div className="freadom-points">
                                            <p className="purple-text">{value.child__freadom_point}</p>
                                            <p>Freadom Points</p>
                                        </div>
                                        <div className="streak-counts">
                                            <p className="purple-text">{value.child__streak}</p>
                                            <p>Streak Count</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )) :
                <div className={'no-data-label'}>Loading....</div>;
        }

        return (
            <Modal
                wrapClassName="children-modal"
                maskStyle={{
                    background: "rgba(0,0,0,0.45)"
                }}
                title="Your Students"
                width={950}
                visible={this.props.visible}
                onOk={null}
                onCancel={this.props.closeModal}
                okText="Submit"
                closeIcon={
                    <i aria-label="icon: close" className="anticon anticon-close ant-modal-close-icon"><CrossIcon /></i>
                }
            >
                <div className="children-cards d-flex wrap">
                    {childrenCards}
                </div>
            </Modal>
        );
    }
}

export default connect(
    ({ student, teacher, user }) => ({ student, ...teacher, ...user }),
    dispatch => bindActionCreators({ ...studentActions, ...teacherActions, ...userActions }, dispatch)
)(ChildrenModal);
